import 'leaflet-editable';
import { Box, OSKThemeType, Typography } from 'oskcomponents';
import React, { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { getPixelSize } from '~/leafletDrawUtil';
import { BaseMapAttributionModal } from '~/molecules/BaseMapAttributionModal';
import { useMap } from '~/hooks';
import { MapMouseCoords } from '~/molecules/MapMouseCoords';

/** Represents the 'distance' legend bar on the map. */
type DistanceBarInfo = {
    /** A description of the distance represented.  */
    label: string;
    /** The multiplier that's applied to a map distance of 1km (1000m)
     * to get this distance. */
    kmConstant: number;
};

/** Provides display data for the scale bar, given a zoom level on the map */
const ZoomLevelDistanceMap: Record<number, DistanceBarInfo> = {
    0: {
        label: '10000 km',
        kmConstant: 10000,
    },
    1: {
        label: '5000 km',
        kmConstant: 5000,
    },
    2: {
        label: '3000 km',
        kmConstant: 3000,
    },
    3: {
        label: '1000 km',
        kmConstant: 1000,
    },
    4: {
        label: '500 km',
        kmConstant: 500,
    },
    5: {
        label: '300 km',
        kmConstant: 300,
    },
    6: {
        label: '200 km',
        kmConstant: 200,
    },
    7: {
        label: '100 km',
        kmConstant: 100,
    },
    8: {
        label: '50 km',
        kmConstant: 50,
    },
    9: {
        label: '30 km',
        kmConstant: 30,
    },
    10: {
        label: '10 km',
        kmConstant: 10,
    },
    11: {
        label: '5 km',
        kmConstant: 5,
    },
    12: {
        label: '3 km',
        kmConstant: 3,
    },
    13: {
        label: '1 km',
        kmConstant: 1,
    },
    14: {
        label: '500 m',
        kmConstant: 0.5,
    },
    15: {
        label: '300 m',
        kmConstant: 0.3,
    },
    16: {
        label: '200 m',
        kmConstant: 0.2,
    },
    17: {
        label: '100 m',
        kmConstant: 0.1,
    },
    18: {
        label: '50 m',
        kmConstant: 0.05,
    },
    19: {
        label: '20 m',
        kmConstant: 0.02,
    },
    20: {
        label: '10 m',
        kmConstant: 0.01,
    },
    21: {
        label: '5 m',
        kmConstant: 0.005,
    },
};

export type OSKMapLowbarProps = {};

export const OSKMapLowbar = () => {
    const map = useMap();
    const zoom = map?.getZoom();

    const lowBarRef = useRef(null);
    const m1000 = zoom !== undefined ? 1000 / getPixelSize(map.getCenter(), zoom) : 0;
    const theme = useTheme() as OSKThemeType;
    const [showAttributionModal, setShowAttributionModal] = useState<boolean>(false);

    const scaleWidth = zoom !== undefined ? m1000 * ZoomLevelDistanceMap[zoom].kmConstant : 0;
    const scaleText = zoom !== undefined ? ZoomLevelDistanceMap[zoom].label : '';

    useDisableFeatureOnMouseOver(lowBarRef, 'Zoom', true);
    useDisableFeatureOnMouseOver(lowBarRef, 'Drag', true);

    const controlContainer: any = document.getElementsByClassName('leaflet-control-scale');
    if (controlContainer.length > 0) {
        for (const $el of controlContainer) {
            $el.style['display'] = 'none';
        }
    }

    return (
        <>
            {showAttributionModal && <BaseMapAttributionModal visible onClose={() => setShowAttributionModal(false)} />}
            <Box
                id="editable-map-lowbar"
                ref={lowBarRef}
                bg={theme.colors.gray1ab}
                center="all"
                style={{
                    position: 'absolute',
                    zIndex: GlobalZIndex.MapSection + 1,
                    width: '100%',
                    height: '20px',
                    bottom: 0,
                    backdropFilter: 'blur(4px)',
                }}
            >
                <Box row center="horizontal" cursor="pointer">
                    <Typography variant="hyperlink2" onClick={() => setShowAttributionModal(true)}>
                        Data Attribution
                    </Typography>
                </Box>
                <Box
                    style={{
                        position: 'absolute',
                        right: '14px',
                    }}
                >
                    {m1000 && (
                        <Box
                            style={{
                                border: '1px solid white',
                                borderTop: 'none',
                                width: `${scaleWidth}px`,
                                height: '10px',
                                position: 'relative',
                                bottom: '-4px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Typography
                                variant="caption2"
                                style={{
                                    marginRight: '4px',
                                    position: 'relative',
                                    bottom: '2px',
                                    fontSize: '0.5em',
                                }}
                            >
                                {scaleText}
                            </Typography>
                        </Box>
                    )}
                    <MapMouseCoords />
                </Box>
            </Box>
        </>
    );
};
